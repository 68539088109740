import React from "react";
import PropTypes from "prop-types";
import { Loader } from "@/components";
import { backEndTranslation } from "@/utils";

import * as S from "./CreditTerms.styles";

const CreditTermsComponent = ({
  principal,
  installment,
  creditGracePeriodInstallment,
  creditInstallment,
  interestRate,
  paymentPeriod,
  numberOfPayments,
  numberOfGracePeriodPayments,
  isLoading,
}) => (
  <S.CreditTerms>
    {principal ? (
      <S.TermRow>
        <S.TermsLabel>Сума на кредита</S.TermsLabel>
        <S.TermsValue>
          <span>{principal} лв.</span>
        </S.TermsValue>
      </S.TermRow>
    ) : null}

    {numberOfGracePeriodPayments ? (
        <S.TermRow>
          <S.TermsLabel>Вноска през гратисен период</S.TermsLabel>
          <S.TermsValue>
            <span>{creditGracePeriodInstallment} лв.</span>
          </S.TermsValue>
        </S.TermRow>
    ) : null}

    {installment ? (
      <S.TermRow>
        <S.TermsLabel>Вноска</S.TermsLabel>
        <S.TermsValue>
          <span>{installment} лв.</span>
        </S.TermsValue>
      </S.TermRow>
    ) : null}

    {creditInstallment && (creditInstallment !== installment) ? (
      <S.TermRow>
        <S.TermsLabel>Вноска с гарант</S.TermsLabel>
        <S.TermsValue>
          <span>{creditInstallment} лв.</span>
        </S.TermsValue>
      </S.TermRow>
    ) : null}

    {interestRate ? (
      <S.TermRow>
        <S.TermsLabel>Лихвен процент</S.TermsLabel>
        <S.TermsValue>
          <span>{interestRate * 100} %</span>
        </S.TermsValue>
      </S.TermRow>
    ) : null}

    {paymentPeriod ? (
      <S.TermRow>
        <S.TermsLabel>Период</S.TermsLabel>
        <S.TermsValue>
          <span>{backEndTranslation(paymentPeriod)}</span>
        </S.TermsValue>
      </S.TermRow>
    ) : null}

    {numberOfPayments ? (
      <S.TermRow>
        <S.TermsLabel>Брой вноски</S.TermsLabel>
        <S.TermsValue>
          <span>{numberOfPayments}</span>
        </S.TermsValue>
      </S.TermRow>
    ) : null}

    {numberOfGracePeriodPayments ? (
        <S.TermRow>
          <S.TermsLabel>Брой гратисни периоди</S.TermsLabel>
          <S.TermsValue>
            <span>{numberOfGracePeriodPayments}</span>
          </S.TermsValue>
        </S.TermRow>
    ) : null}

    <Loader {...{ isLoading }} size="medium" background="light" />
  </S.CreditTerms>
);

CreditTermsComponent.defaultProps = {
  creditInstallment: 0,
  creditGracePeriodInstallment: 0,
  installment: 0,
  gracePeriodInstallment: 0,
  interestRate: 0,
  isLoading: false,
  numberOfPayments: "",
  numberOfGracePeriodPayments: "",
  paymentPeriod: "",
  principal: 0,
};

CreditTermsComponent.propTypes = {
  creditGracePeriodInstallment: PropTypes.number,
  gracePeriodInstallment: PropTypes.number,
  creditInstallment: PropTypes.number,
  installment: PropTypes.number,
  interestRate: PropTypes.number,
  isLoading: PropTypes.bool,
  numberOfPayments: PropTypes.string,
  numberOfGracePeriodPayments: PropTypes.string,
  paymentPeriod: PropTypes.string,
  principal: PropTypes.number,
};

export default CreditTermsComponent;
